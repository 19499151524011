<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="card-title float-left">Kunden</div>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="success"
                  class="float-right"
                  fab
                  @click="dialog = true"
                >
                  <i class="material-icons">add</i>
                </v-btn>
              </template>
              <span>Neuen Kunden anlegen</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Suche"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="headers"
              :items="items"
              class="row-pointer"
              @click:row="handleClick"
              :items-per-page="25"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1],
              }"
              :search="search"
            >
              <template v-slot:item.address="{ item }"
                >{{ item.street }} {{ item.houseno }}, {{ item.zip }}
                {{ item.city }}</template
              >
              <template v-slot:item.wartungsvertrag="{ item }">
                <span v-if="item.wartungsvertrag === '1'">Ja</span>
                <span v-else>Nein</span>
              </template>
              <template v-slot:item.active="{ item }">
                <v-icon
                  color="green darken-2"
                  v-if="item.active === '1' || item.active === 1"
                  >mdi-checkbox-blank-circle</v-icon
                >
                <v-icon color="red" v-else>mdi-checkbox-blank-circle</v-icon>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="text-center inline-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="$router.push('/customer/' + item.id)"
                        fab
                        small
                        color="primary"
                      >
                        <v-icon class="material-icons"
                          >mdi-information-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Kundendetails anzeigen</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click.stop="confirmDeleteItem(item.id, item.name)"
                        color="error"
                        small
                        fab
                      >
                        <i class="material-icons">delete</i>
                      </v-btn>
                    </template>
                    <span>Kunden löschen</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
        <v-card >
          <v-card-title>
            <span class="headline">Neuen Kunden erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <v-flex xs12 sm12>
                    <v-text-field
                      v-model="newCustomer.name"
                      label="Name*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      v-model="newCustomer.companynumber"
                      label="Kundennummer*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      v-model="newCustomer.customercode"
                      label="Kundenkürzel*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-checkbox
                      v-model="newCustomer.wartungsvertrag"
                      label="Wartungsvertrag"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <div class="sub-title">Rechnungsadresse</div>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="newCustomer.contactperson"
                      label="Kontaktperson"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      type="email"
                      v-model="newCustomer.email"
                      label="E-Mail"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      type="phone"
                      v-model="newCustomer.phone"
                      label="Telefon"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm8 md8>
                    <v-text-field
                      v-model="newCustomer.street"
                      label="Straße*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="newCustomer.houseno"
                      label="Hausnummer*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md3>
                    <v-text-field
                      type="number"
                      v-model="newCustomer.zip"
                      max="99999"
                      label="PLZ*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm7 md9>
                    <v-text-field
                      v-model="newCustomer.city"
                      label="Stadt*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <div class="sub-title">
                      Standort (
                      <small
                        >Weitere Standorte können im Anschluss angelegt
                        werden</small
                      >)
                    </div>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-checkbox
                      v-model="likeBillingAddress"
                      label="Wie Rechnungsadresse"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm12 md8 v-if="!likeBillingAddress">
                    <v-text-field
                      v-model="newCustomer.standortname"
                      label="Bezeichnung*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4 v-if="!likeBillingAddress">
                    <v-text-field
                      v-model="newCustomer.standortcontactperson"
                      label="Kontaktperson"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4 v-if="!likeBillingAddress">
                    <v-text-field
                      type="email"
                      v-model="newCustomer.standortemail"
                      label="E-Mail"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4 v-if="!likeBillingAddress">
                    <v-text-field
                      type="phone"
                      v-model="newCustomer.standortphone"
                      label="Telefon"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm8 md8 v-if="!likeBillingAddress">
                    <v-text-field
                      v-model="newCustomer.standortstreet"
                      label="Straße*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4 v-if="!likeBillingAddress">
                    <v-text-field
                      v-model="newCustomer.standorthouseno"
                      label="Hausnummer*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md3 v-if="!likeBillingAddress">
                    <v-text-field
                      type="number"
                      v-model="newCustomer.standortzip"
                      max="99999"
                      label="PLZ*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm7 md9 v-if="!likeBillingAddress">
                    <v-text-field
                      v-model="newCustomer.standortcity"
                      label="Stadt*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="newCustomer.comment"
                      outlined
                      label="Kommentar"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createNewCustomer()"
              >Speichern</v-btn
            >
            <v-btn color="error" text @click="dialog = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Kunden löschen</v-card-title>
          <v-card-text>
            Sind sie sicher, dass Sie die Kunden
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteItem()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      likeBillingAddress: false,
      newCustomer: {
        comment:"",
        name: "",
        companynumber: "",
        customercode: "",
        contactperson: "",
        email: "",
        phone: "",
        street: "",
        houseno: "",
        zip: "",
        city: "",
        standortname: "",
        standortcontactperson: "",
        standortemail: "",
        standortphone: "",
        standortstreet: "",
        standorthouseno: "",
        standortzip: "",
        standortcity: "",
        wartungsvertrag: false,
      },
      deleteName: "",
      deleteId: "",
      search: "",
      headers: [
        {
          text: "Kundennummer",
          align: "left",
          value: "companynumber",
        },
        {
          text: "Kundenkürzel",
          align: "left",
          value: "customercode",
        },
        {
          text: "Firmenname",
          value: "name",
        },
        {
          text: "Kontaktperson",
          sortable: false,
          value: "contactperson",
        },
        {
          text: "E-Mail",
          sortable: false,
          value: "email",
        },
        {
          text: "Telefon",
          sortable: false,
          value: "phone",
        },
        {
          text: "Rechnnugsadresse",
          sortable: false,
          value: "address",
        },
        {
          text: "Wartungsvertrag",
          sortable: false,
          align: "center",
          value: "wartungsvertrag",
        },
        {
          text: "Aktiv",
          value: "active",
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action",
        },
      ],
      items: [],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    online() {
      return this.$store.getters.online;
    },
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    handleClick(item) {
      this.$router.push("/customer/" + item.id);
    },
    createNewCustomer() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let customer = JSON.parse(JSON.stringify(this.newCustomer));
      customer.wartungsvertrag = customer.wartungsvertrag ? 1 : 0;
      /*   this.$http
        .post("customer/addcustomer", customer) */
      let formData = new FormData();
      formData.append("mode", "new");
      formData.append("json", JSON.stringify(this.newCustomer));
      formData.append("likeBillingAddress", this.likeBillingAddress);
      this.$http({
        method: "post",
        url: "editCustomer.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.status === 200 && response.data && response.data.id) {
            /*  customer.id = response.data.id;
            this.items.push(customer);
            this.dialog = false;
            Object.keys(this.newCustomer).forEach((prop) => {
              if (prop === "wartungsvertrag") {
                this.newCustomer[prop] = false;
              } else {
                this.newCustomer[prop] = "";
              }
              this.likeBillingAddress = false;
            }); */

            const msg = "Der Kunde wurde erfolgreich erstellt.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.$router.push("/customer/" + response.data.id);
          }else {
            const msg = "Der Kunde wurde konnte nicht erstellt werden.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response;
          if (
            response.status === 500 &&
            response.data &&
            response.data.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          const msg =
            "Die Kunde konnte nicht erstellt werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    getCustomers() {
      let self = this;
      if (this.online) {
        this.$http({
          method: "post",
          url: "getCustomers.php",
        })
          .then((response) => {
            if (
              response.status === 500 &&
              response.message === "NoLoggedInUser"
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (
              response.status === 200 &&
              response.data &&
              response.data.data
            ) {
              self.items = response.data.data;
            }
          })
          .catch((error) => {
            const response = error.response.data;
            if (
              response.status === 500 &&
              response.message === "NoLoggedInUser"
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
      } else {
        const data = this.$store.getters.offlinedata;
        if (data.customers) {
          self.items = data.customers;
        }
      }
    },
    confirmDeleteItem(id, name) {
      this.deleteName = name;
      this.deleteId = id;
      this.confirm = true;
    },
    deleteItem() {
      let formData = new FormData();
      formData.append("id", this.deleteId);
      this.$http({
        method: "post",
        url: "deleteCustomer.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 203) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.status === 200) {
            this.items.forEach((a, i) => {
              if (a.id === this.deleteId) {
                this.items.splice(i, 1);
                this.deleteName = "";
                this.deleteId = "";
                this.confirm = false;
              }
            });
            const msg = "Der Kunde wurde erfolgreich gelöscht.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Der Kunde konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          const msg =
            "Der Kunde konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
  },
};
</script>